<template>
  <md-dialog :md-active="open" class="modalEdit__instructors modal-categories">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">CREAR UNA CATEGORIA</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2">
            <base-input
              v-model="formData.title"
              name="name"
              placeholder="Coloca el nombre"
            ></base-input>
          </div>
        </div>

        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia la imagen de la categoria</label>
            <input
              id="file-input"
              accept="image/*"
              type="file"
              @change="uploadCategoryImage"
            />
          </md-field>
        </div>

        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia la imagen de la categoria</label>
            <input
              id="file-input-seo"
              accept="image/*"
              type="file"
              @change="uploadSeoImage"
            />
          </md-field>
        </div>

        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia la imagen de la categoria</label>
            <input
              id="icon-input"
              accept="image/*"
              type="file"
              @change="uploadIcon"
            />
          </md-field>
        </div>

        <div class="mt-2">
          <div class="row mt-2">
            <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2">
              <div class="mt-2">
                <h4 class="text-bold text-left">SELECCIONA UNA IMAGEN</h4>
              </div>
              <div class="imageContainer">
                <img
                  v-if="this.formData.categoryFile.url"
                  :src="this.formData.categoryFile.url"
                  alt="productImage"
                  class="imageToUpload"
                />
                <img
                  v-else
                  alt="productImage"
                  class="imageToUpload"
                  src="@/assets/images/logo/placeholder.png"
                />
                <div class="textUploadImage" @click="openInputFile">
                  <p>Sube la imagen de la categoria</p>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2">
              <div class="mt-2">
                <h4 class="text-bold text-left">SELECCIONA UN ICONO</h4>
              </div>
              <div class="imageContainer">
                <img
                  v-if="this.formData.categoryIconFile.url"
                  :src="this.formData.categoryIconFile.url"
                  alt="productImage"
                  class="imageToUpload"
                />
                <img
                  v-else
                  alt="productImage"
                  class="imageToUpload"
                  src="@/assets/images/logo/placeholder.png"
                />
                <div class="textUploadImage" @click="openInputIcon">
                  <p>Sube un icono</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <div class="row mt-2">
            <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2">
              <div class="text-left mt-3">
                <h4 class="text-bold">FOTO PRINCIPAL SEO</h4>
              </div>
              <div class="imageContainer">
                <img
                  v-if="this.formData.categorySeoFile.url"
                  :src="this.formData.categorySeoFile.url"
                  alt="productImage"
                  class="imageToUpload"
                />
                <img
                  v-else
                  alt="productImage"
                  class="imageToUpload"
                  src="@/assets/images/logo/placeholder.png"
                />
                <div class="textUploadImage" @click="openInputFileSeo">
                  <p>Sube la imagen principal</p>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2">
              <div class="text-left mt-3">
                <h4 class="text-bold mb-0">TITULO SEO</h4>
              </div>
              <base-input
                v-model="formData.seoTitle"
                name="name"
                placeholder="Coloca el Titulo SEO"
              ></base-input>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2">
              <div class="text-left mt-3">
                <h4 class="text-bold mb-0">DESCRIPCIÓN SEO</h4>
              </div>
              <textarea
                v-model="formData.seoDescription"
                class="form-control mt-3"
                placeholder="Descripción SEO"
                rows="10"
              ></textarea>
            </div>
          </div>
        </div>

        <md-dialog-actions
          class="modal-footer"
          style="flex-direction: inherit !important"
        >
          <base-button
            class="md-primary"
            style="width: 115px"
            @click="closeModal"
            >CANCELAR
          </base-button>
          <base-button
            :loading="isLoading"
            class="md-primary"
            style="width: 115px"
            @click="createCategory"
          >
            CREAR
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { CRETE_CATEGORY } from '../../../../store/actions/categories';
import BaseButton from '../../../../components/BaseButton';

export default {
  name: 'CreateCourseCategoryDialog',
  components: {
    BaseButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      formData: {
        title: '',
        url: '',
        categoryFile: {
          url: '',
        },
        categoryIconFile: {
          url: '',
        },
        categorySeoFile: {
          url: '',
        },
        seoTitle: '',
        seoDescription: '',
      },
      isLoading: false,
      categoryIconFile: undefined,
      categoryImageFile: undefined,
      categorySeoImageFile: undefined,
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  methods: {
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    reset() {
      this.formData = {
        title: '',
        url: '',
        categoryFile: {
          url: '',
        },
        categoryIconFile: {
          url: '',
        },
        categorySeoFile: {
          url: '',
        },
        seoTitle: '',
        seoDescription: '',
      };
      this.categoryIconFile = undefined;
      this.categoryImageFile = undefined;
      this.categorySeoImageFile = undefined;
    },
    uploadIcon(event) {
      const files = event.target.files;
      this.formData.categoryIconFile.url = URL.createObjectURL(files[0]);
      this.iconFile = files[0];
    },
    uploadCategoryImage(event) {
      const files = event.target.files;

      this.formData.categoryFile.url = URL.createObjectURL(files[0]);
      this.categoryImageFile = files[0];
    },
    uploadSeoImage(event) {
      const files = event.target.files;
      this.formData.categorySeoFile.url = URL.createObjectURL(files[0]);
      this.categorySeoImageFile = files[0];
    },
    createCategory() {
      const data = new FormData();

      data.append('title', this.formData.title);
      data.append('seo_title', this.formData.seoTitle);
      data.append('seo_description', this.formData.seoDescription);

      if (this.iconFile) {
        data.append('category_icon_file', this.iconFile);
      }

      if (this.categoryImageFile) {
        data.append('category_file', this.categoryImageFile);
      }

      if (this.categorySeoImageFile) {
        data.append('category_seo_file', this.categorySeoImageFile);
      }
      for (let [key, value] of data.entries()) {
        console.log(key, value);
      }

      this.isLoading = true;
      this.$store
        .dispatch(CRETE_CATEGORY, data)
        .then(() => {
          this.isLoading = false;
          this.$emit('success');
          this.notify('success', 'Se ha creado la categoria correctamente');
          this.closeModal();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para crear la categoria');
          this.isLoading = false;
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    openInputFile() {
      document.getElementById('file-input').click();
    },
    openInputFileSeo() {
      document.getElementById('file-input-seo').click();
    },
    openInputIcon() {
      document.getElementById('icon-input').click();
    },
  },
};
</script>

<style scoped>
.imageContainer img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  align-items: center;
}

.imageContainer .textUploadImage {
  height: 300px;
  width: 100%;
}
</style>
