<template>
  <md-dialog
    :md-active="open"
    class="modalEdit__instructors modal-categories update-course-category-dialog"
  >
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">EDITAR CATEGORIA</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2">
            <base-input
              v-model="formData.title"
              name="name"
              placeholder="Coloca el nuevo nombre"
            ></base-input>
          </div>
        </div>

        <div class="col-md-6"></div>

        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia la imagen principal de la categoria</label>
            <input
              id="file-input-seo"
              accept="image/*"
              type="file"
              @change="uploadSeoImage"
            />
          </md-field>
        </div>

        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia el icono de la categoria</label>
            <input
              id="icon-input"
              accept="image/*"
              type="file"
              @change="uploadIcon"
            />
          </md-field>
        </div>

        <div class="row mt-2">
          <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2">
            <div class="mt-2">
              <h4 class="text-bold text-left">SELECCIONA UNA IMÁGEN</h4>
            </div>

            <div class="imageContainer">
              <div class="dropzone-profile-course seo-instructor">
                <md-field
                  class="pt-0 d-flex"
                  style="margin: 0; justify-content: flex-start"
                >
                  <md-file
                    :style="{
                      backgroundImage: `url('${
                        formData.categoryFile.url
                          ? formData.categoryFile.url
                          : require('@/assets/images/logo/placeholder.png')
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      zIndex: '11',
                    }"
                    accept="image/*"
                    class="design-dropzone instructor-modal col-12 px-0 border-0"
                    @md-change="uploadCategoryImage($event)"
                  />
                  <div class="textUploadImage">
                    <p>Sube la imagen principal</p>
                  </div>
                </md-field>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2">
            <div class="mt-2">
              <h4 class="text-bold text-left">SELECCIONA UN ICONO</h4>
            </div>

            <div class="imageContainer">
              <div class="dropzone-profile-course seo-instructor">
                <md-field
                  class="pt-0 d-flex"
                  style="margin: 0; justify-content: flex-start"
                >
                  <md-file
                    :style="{
                      backgroundImage: `url('${
                        formData.categoryIconFile.url
                          ? formData.categoryIconFile.url
                          : require('@/assets/images/logo/placeholder.png')
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      zIndex: '11',
                    }"
                    accept="image/*"
                    class="design-dropzone instructor-modal col-12 px-0 border-0"
                    @md-change="uploadIcon($event)"
                  />
                  <div class="textUploadImage">
                    <p>Sube la imagen principal</p>
                  </div>
                </md-field>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="row mt-2">
            <div class="col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2">
              <div class="text-left mt-3">
                <h4 class="text-bold">FOTO PRINCIPAL SEO</h4>
              </div>
              <div class="imageContainer">
                <div class="dropzone-profile-course seo-instructor">
                  <md-field
                    class="pt-0 d-flex"
                    style="margin: 0; justify-content: flex-start"
                  >
                    <md-file
                      :style="{
                        backgroundImage: `url('${
                          formData.categorySeoFile.url
                            ? formData.categorySeoFile.url
                            : require('@/assets/images/logo/placeholder.png')
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        zIndex: '11',
                      }"
                      accept="image/*"
                      class="design-dropzone instructor-modal col-12 px-0 border-0"
                      @md-change="uploadSeoImage($event)"
                    />
                    <div class="textUploadImage">
                      <p>Sube la imagen principal</p>
                    </div>
                  </md-field>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2">
              <div class="text-left mt-3">
                <h4 class="text-bold mb-0">TITULO SEO</h4>
              </div>
              <base-input
                v-model="formData.seoTitle"
                name="name"
                placeholder="Coloca el Titulo SEO"
              ></base-input>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2">
              <div class="text-left mt-3">
                <h4 class="text-bold mb-0">DESCRIPCIÓN SEO</h4>
              </div>
              <textarea
                v-model="formData.seoDescription"
                class="form-control mt-3"
                placeholder="Descripción SEO"
                rows="10"
              ></textarea>
            </div>
          </div>
        </div>
        <md-dialog-actions
          class="modal-footer"
          style="flex-direction: inherit !important"
        >
          <md-button
class="md-primary w-auto"
@click="closeModal"
            >CANCELAR
          </md-button>
          <base-button
            :loading="isLoading"
            class="md-primary w-auto"
            type="submit"
            @click="updateCourseCategory"
            >ACTUALIZAR
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { FETCH_COURSES } from '../../../../store/actions/course';
import { mapGetters } from 'vuex';
import { UPDATE_CATEGORY } from '../../../../store/actions/categories';
import BaseButton from '../../../../components/BaseButton';

export default {
  name: 'UpdateCourseCategoryDialog',
  components: {
    BaseButton,
  },
  props: {
    selectedCourseCategory: {
      type: Object,
      default: undefined,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-modal', 'success'],
  data() {
    return {
      formData: {
        title: '',
        categoryFile: {
          url: '',
        },
        categoryIconFile: {
          url: '',
        },
        categorySeoFile: {
          url: '',
        },
        seoTitle: '',
        seoDescription: '',
        courses: [],
      },
      isLoading: false,
      isLoadingCourses: true,
      categoryIconFile: undefined,
      categoryImageFile: undefined,
      categorySeoImageFile: undefined,
    };
  },
  computed: {
    ...mapGetters(['getAllCourses']),
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  watch: {
    selectedCourseCategory: function (newValue) {
      if (newValue)
        this.formData = {
          id: newValue.id,
          title: newValue.title,
          categoryFile: newValue.course_category_file,
          categoryIconFile: newValue.course_category_icon_file,
          categorySeoFile: newValue.course_category_seo_file,
          seoTitle: newValue.seo_title ? newValue.seo_title : '',
          seoDescription: newValue.seo_description
            ? newValue.seo_description
            : '',
          courses: newValue.courses.map(({ course }) => course),
        };
      else this.reset();
    },
  },
  mounted() {
    this.fetchCourses();
  },
  methods: {
    validateUpdateCourseCategory() {
      const errors = [];

      if (!this.formData.title) {
        errors.push('El nombre es requerido');
      }

      return errors;
    },
    searchCourse(searchCourseQuery) {
      this.fetchCourses({ search: searchCourseQuery });
    },
    reset() {
      this.formData = {
        title: '',
        url: '',
        categoryFile: {
          url: '',
        },
        categoryIconFile: {
          url: '',
        },
        categorySeoFile: {
          url: '',
        },
        seoTitle: '',
        seoDescription: '',
        courses: [],
      };
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    fetchCourses(filter = {}) {
      this.isLoadingCourses = true;
      return this.$store
        .dispatch(FETCH_COURSES, filter)
        .then(() => (this.isLoadingCourses = false));
    },
    updateCourseCategory() {
      this.isLoading = true;
      this.isCategoryUpdated = true;

      const data = new FormData();
      data.append('id', this.formData.id);
      data.append('title', this.formData.title);
      data.append('seo_title', this.formData.seoTitle);
      data.append('seo_description', this.formData.seoDescription);
      data.append('courses', JSON.stringify(this.formData.courses));

      if (this.iconFile) {
        data.append('course_category_icon_file', this.iconFile);
      }

      if (this.categoryImageFile) {
        data.append('course_category_file', this.categoryImageFile);
      }

      if (this.categorySeoImageFile) {
        data.append('course_category_seo_file', this.categorySeoImageFile);
      }

      this.$store
        .dispatch(UPDATE_CATEGORY, data)
        .then(() => {
          this.$emit('success');
          this.notify(
            'success',
            'Se ha actualizado la categoria correctamente',
          );
          this.isLoading = false;
          this.closeModal();
        })
        .catch(() => {
          this.isLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la categoria',
          );
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    uploadIcon(files) {
      this.formData.categoryIconFile.url = URL.createObjectURL(files[0]);
      this.iconFile = files[0];
    },
    uploadCategoryImage(files) {
      this.formData.categoryFile.url = URL.createObjectURL(files[0]);
      this.categoryImageFile = files[0];
    },
    uploadSeoImage(files) {
      this.formData.categorySeoFile.url = URL.createObjectURL(files[0]);
      this.categorySeoImageFile = files[0];
    },
  },
};
</script>

<style>
.md-menu-content {
  z-index: 2 !important;
}

.update-course-category-dialog .multiselect__option--highlight {
  background: #000;
  outline: none;
  color: #fff;
}

.update-course-category-dialog .imageContainer {
  display: flex;
}

.update-course-category-dialog .imageContainer .textUploadImage {
  max-height: 300px;
  width: 100%;
}

.update-course-category-dialog .multiselect__select {
  margin-top: 0px;
}

.update-course-category-dialog .imageToUpload {
  width: 100%;
  object-fit: cover;
  border: 0px;
  object-position: center;
}

.update-course-category-dialog .multiselect__single {
  display: flex;
  align-items: center; /* centra verticalmente */
}

.update-course-category-dialog .dialog-body .md-field .md-file input {
  border: 0px;
  margin-left: 0px;
  min-height: 300px;
  border-radius: 0px;
  cursor: pointer;
}

.update-course-category-dialog .design-dropzone {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
