<template>
  <md-dialog :md-active="open" class="modalEdit__instructors modal-categories">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold text-center">Se requiere tu atención</h3>
      </div>

      <div class="py-5 pl-7 pr-7 text-center p-5">
        <i class="ni ni-bell-55 ni-3x p-5"></i>
        <h4 class="heading mt-4">¡Deberias leer esto!</h4>
        <p>¿Estas seguro que deseas eliminar?</p>
      </div>

      <md-dialog-actions
        class="modal-footer"
        style="flex-flow: inherit !important"
      >
        <base-button class="md-primary w-auto" @click="closeModal">
          CERRAR
        </base-button>
        <base-button class="md-primary w-auto" @click="deleted">
          <span> ELIMINAR </span>
        </base-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import BaseButton from '../../../../components/BaseButton';

export default {
  name: 'DeleteDialog',
  components: {
    BaseButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['deleted', 'close-modal'],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  mounted() {
    this.isLoading = false;
  },
  methods: {
    deleted() {
      this.isLoading = true;
      this.$emit('deleted');
    },
    closeModal() {
      this.isLoading = false;
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped>
.font-bold {
  font-weight: 600;
  cursor: pointer;
  float: right;
  font-family: 'Barlow', sans-serif !important;
}

.btn-delete {
  background: #b7227e;
  color: #fff;
  font-weight: 500;
  margin-left: 8px;
  margin-bottom: 8px;
  padding: 5px 12px;
  font-size: 12px;
  letter-spacing: 0.7px;
  border-radius: 5px;
}

.btn-delete-close {
  background: #000;
  color: #fff;
  font-weight: 500;
  margin-left: 8px;
  margin-bottom: 8px;
  padding: 5px 12px;
  font-size: 12px;
  letter-spacing: 0.7px;
  border-radius: 5px;
  outline: none;
}

.btn-delete-close span {
  font-family: 'Barlow', sans-serif !important;
  color: #fff;
  font-weight: 600;
}

.btn-delete span {
  color: #fff;
  font-family: 'Barlow', sans-serif !important;
  font-weight: 600;
}
</style>
