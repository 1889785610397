<template>
  <div class="container-fluid container-category">
    <div class="row mb-3">
      <div class="col-xs-courses col-sm-8 col-md-8 col-lg-8 col-lg-8 col-xl-8">
        <form class="filter-flex">
          <i class="fas fa-search fa-flip-horizontal"></i>
          <input
            v-model="searchValue"
            aria-label="Search"
            class="form-control filter-input__category inputFilter me-2"
            placeholder="Buscar curso por nombre"
            type="text"
          />
          <div class="md-layout md-gutter">
            <div class="md-layout-item" style="padding: 0 !important">
              <md-field class="sort-box" style="">
                <md-select
                  v-model="sortType"
                  class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                >
                  <md-option hidden selected value="">Filtrar por</md-option>
                  <md-option value="newest">Nuevo</md-option>
                  <md-option value="oldest">Antiguo</md-option>
                  <md-option value="asc">A-Z</md-option>
                  <md-option value="desc">Z-A</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </form>
      </div>
      <div
        class="col-xs-courses col-sm-4 col-md-4 col-lg-4 col-lg-4 col-xl-4 pl-3"
      >
        <button
          class="btn btn-course btn-course__librery m-0 float-right"
          @click="openCreateCourseCategoryDialog"
        >
          Crear categoria
        </button>
      </div>
    </div>
    <card class="mt-3">
      <!--Tablaaa-->
      <div style="overflow-x: auto">
        <md-table>
          <md-table-row class="container-instructors__head">
            <md-table-head>Imagen</md-table-head>
            <md-table-head>Nombre</md-table-head>
            <md-table-head>Cursos</md-table-head>
            <md-table-head>Clases</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
          <md-table-row v-for="item in this.getCategories.items" :key="item.id">
            <md-table-cell class="md-table-cell">
              <img
                :alt="item.title"
                :src="
                  item.course_category_file && item.course_category_file.url
                    ? item.course_category_file.url
                    : ''
                "
                width="50px"
              />
            </md-table-cell>
            <md-table-cell class="md-table-cell vertical-align">{{
              item.title
            }}</md-table-cell>
            <md-table-cell>
              <div class="d-flex flex-row bd-highlight">
                <md-avatar
                  :class="[
                    (item.courses || []).length ? 'coursesActives' : 'bg-light',
                  ]"
                  class="md-avatar-icon ml-0 mr-2"
                >
                  {{ (item.courses || []).length }}
                </md-avatar>
                <div v-if="(item.courses || []).length" class="py-1">
                  <i
                    class="fas fa-bars"
                    role="button"
                    @click="handleCategoryCourses(item)"
                  ></i>
                </div>
              </div>
            </md-table-cell>
            <!-- CLASES DE LA CATEGORIA -->
            <md-table-cell>
              <div class="d-flex flex-row bd-highlight">
                <md-avatar
                  :class="[
                    (item.classes || []).length ? 'coursesActives' : 'bg-light',
                  ]"
                  class="md-avatar-icon ml-0 mr-2"
                >
                  {{ (item.classes || []).length }}
                </md-avatar>
                <div v-if="(item.classes || []).length" class="py-1">
                  <i
                    class="fas fa-bars"
                    role="button"
                    @click="handleCategoryClasses(item)"
                  ></i>
                </div>
              </div>
            </md-table-cell>
            <!-- CLASES DE LA CATEGORIA -->

            <md-table-cell
              class="md-table-cell"
              style="padding-top: 0px !important"
            >
              <span class="float-right">
                <md-menu :md-offset-x="-127" :md-offset-y="-20" md-size="auto">
                  <button class="button-center-options" md-menu-trigger>
                    •••
                  </button>

                  <md-menu-content>
                    <md-menu-item>
                      <span
                        class="pr-5 cursor"
                        href
                        @click="openUpdateCourseCategoryDialog(item)"
                      >
                        Editar
                      </span>
                    </md-menu-item>
                    <md-menu-item>
                      <span
                        class="pr-5 cursor"
                        href
                        @click="openDeleteCourseCategoryDialog(item)"
                      >
                        Eliminar
                      </span>
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
              </span>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div
          v-if="totalPages > 1"
          class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
    </card>
    <create-course-category-dialog
      :is-open="isOpenCreateCourseCategoryDialog"
      @success="fetchCategories"
      @close-modal="closeCreateCourseCategoryDialog"
    />
    <delete-dialog
      :is-open="isOpenDeleteCourseCategoryDialog"
      @deleted="deleteCourseCategory"
      @close-modal="closeDeleteCourseCategoryDialog"
    />
    <update-course-category-dialog
      :is-open="isOpenUpdateCourseCategoryDialog"
      :selected-course-category="selectedCourseCategory"
      @success="fetchCategories"
      @close-modal="closeUpdateCourseCategoryDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  DELETE_CATEGORY,
  FETCH_CATEGORIES,
} from '../../../store/actions/categories';
import CreateCourseCategoryDialog from './components/CreateCourseCategoryDialog';
import UpdateCourseCategoryDialog from './components/UpdateCourseCategoryDialog';
import DeleteDialog from './components/DeleteDialog';
import BasePagination from '../../../components/BasePagination';

export default {
  name: 'AdminCategories',
  components: {
    DeleteDialog,
    CreateCourseCategoryDialog,
    UpdateCourseCategoryDialog,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      sortType: '',
      searchValue: null,
      isOpenCreateCourseCategoryDialog: false,
      isOpenDeleteCourseCategoryDialog: false,
      isOpenUpdateCourseCategoryDialog: false,
      selectedCourseCategory: undefined,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getCategories', 'getAllCourses', 'getAllClass']),
    totalPages() {
      return Math.ceil(this.getCategories.total / this.perPage);
    },
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories(
      filter = {
        search: this.searchValue,
        per_page: this.perPage,
        order_by: this.sortType,
      },
    ) {
      this.isLoadingCategories = true;
      this.$store.dispatch(FETCH_CATEGORIES, filter).then(() => {
        this.isLoadingCategories = false;
      });
    },

    openUpdateCourseCategoryDialog(courseCategory) {
      this.selectedCourseCategory = courseCategory;
      this.isOpenUpdateCourseCategoryDialog = true;
    },
    closeUpdateCourseCategoryDialog() {
      this.selectedCourseCategory = undefined;
      this.isOpenUpdateCourseCategoryDialog = false;
    },

    openCreateCourseCategoryDialog() {
      this.isOpenCreateCourseCategoryDialog = true;
    },
    closeCreateCourseCategoryDialog() {
      this.isOpenCreateCourseCategoryDialog = false;
    },

    openDeleteCourseCategoryDialog(courseCategory) {
      this.selectedCourseCategory = courseCategory;
      this.isOpenDeleteCourseCategoryDialog = true;
    },
    closeDeleteCourseCategoryDialog() {
      this.selectedCourseCategory = undefined;
      this.isOpenDeleteCourseCategoryDialog = false;
    },
    deleteCourseCategory() {
      this.$store
        .dispatch(DELETE_CATEGORY, this.selectedCourseCategory.id)
        .then(() => {
          this.notify('success', 'Se ha eliminado la categoria con éxito');
          this.fetchCategories();
          this.closeDeleteCourseCategoryDialog();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para eliminar la categoria');
        });
    },
    handleCategoryCourses(courseCategory) {
      this.$router.push(`curso/categoria/${courseCategory.id}`);
    },
    handleCategoryClasses(courseCategory) {
      this.$router.push(`curso/categoria/${courseCategory.id}/clases`);
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_CATEGORIES, {
        page: this.page,
        per_page: this.perPage,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style>
.md-field .md-input:focus,
.md-field .md-textarea:focus,
.md-select-value,
.md-select-value:active {
  border: none !important;
}

.filter-input__category {
  max-width: 100%;
}

.form-control {
  height: 50px;
  margin-bottom: 10px;
}

.md-sort-select {
  margin-right: 0px;
}

textarea.form-control {
  height: 250px !important;
}

.table td,
.table th,
.el-table td,
.el-table th {
  color: #000;
  font-size: 0.8125rem;
  white-space: nowrap;
}

.button-center-options {
  text-align-last: center;
  align-self: center;
  padding-bottom: 6px;
  background: white;
  border: none;
  vertical-align: middle;
  margin: 0px !important;
  font-size: 25px;
}
.md-table-cell-container {
  padding: 0px !important;
}

.card-body {
  padding: 0px !important;
}

.card-body .md-content {
  padding: 0px !important;
}
</style>
