var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-dialog',{staticClass:"modalEdit__instructors modal-categories update-course-category-dialog",attrs:{"md-active":_vm.open}},[_c('div',{staticClass:"w-100 position-fixed mobile-cancel"},[_c('span',{staticClass:"material-icons close text-right",on:{"click":_vm.closeModal}},[_vm._v("close")])]),_c('div',{staticClass:"body-modal-instructor modal-body"},[_c('div',{staticClass:"md-dialog-title text-left"},[_c('h3',{staticClass:"text-bold"},[_vm._v("EDITAR CATEGORIA")])]),_c('div',{staticClass:"form-instructor dialog-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2"},[_c('base-input',{attrs:{"name":"name","placeholder":"Coloca el nuevo nombre"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})],1)]),_c('div',{staticClass:"col-md-6"}),_c('div',{staticClass:"col-md-6"},[_c('md-field',{staticClass:"d-none"},[_c('label',[_vm._v("Cambia la imagen principal de la categoria")]),_c('input',{attrs:{"id":"file-input-seo","accept":"image/*","type":"file"},on:{"change":_vm.uploadSeoImage}})])],1),_c('div',{staticClass:"col-md-6"},[_c('md-field',{staticClass:"d-none"},[_c('label',[_vm._v("Cambia el icono de la categoria")]),_c('input',{attrs:{"id":"icon-input","accept":"image/*","type":"file"},on:{"change":_vm.uploadIcon}})])],1),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2"},[_c('div',{staticClass:"mt-2"},[_c('h4',{staticClass:"text-bold text-left"},[_vm._v("SELECCIONA UNA IMÁGEN")])]),_c('div',{staticClass:"imageContainer"},[_c('div',{staticClass:"dropzone-profile-course seo-instructor"},[_c('md-field',{staticClass:"pt-0 d-flex",staticStyle:{"margin":"0","justify-content":"flex-start"}},[_c('md-file',{staticClass:"design-dropzone instructor-modal col-12 px-0 border-0",style:({
                      backgroundImage: `url('${
                        _vm.formData.categoryFile.url
                          ? _vm.formData.categoryFile.url
                          : require('@/assets/images/logo/placeholder.png')
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      zIndex: '11',
                    }),attrs:{"accept":"image/*"},on:{"md-change":function($event){return _vm.uploadCategoryImage($event)}}}),_c('div',{staticClass:"textUploadImage"},[_c('p',[_vm._v("Sube la imagen principal")])])],1)],1)])]),_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2"},[_c('div',{staticClass:"mt-2"},[_c('h4',{staticClass:"text-bold text-left"},[_vm._v("SELECCIONA UN ICONO")])]),_c('div',{staticClass:"imageContainer"},[_c('div',{staticClass:"dropzone-profile-course seo-instructor"},[_c('md-field',{staticClass:"pt-0 d-flex",staticStyle:{"margin":"0","justify-content":"flex-start"}},[_c('md-file',{staticClass:"design-dropzone instructor-modal col-12 px-0 border-0",style:({
                      backgroundImage: `url('${
                        _vm.formData.categoryIconFile.url
                          ? _vm.formData.categoryIconFile.url
                          : require('@/assets/images/logo/placeholder.png')
                      }')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      zIndex: '11',
                    }),attrs:{"accept":"image/*"},on:{"md-change":function($event){return _vm.uploadIcon($event)}}}),_c('div',{staticClass:"textUploadImage"},[_c('p',[_vm._v("Sube la imagen principal")])])],1)],1)])])]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-6 d-flex flex-column p-2"},[_c('div',{staticClass:"text-left mt-3"},[_c('h4',{staticClass:"text-bold"},[_vm._v("FOTO PRINCIPAL SEO")])]),_c('div',{staticClass:"imageContainer"},[_c('div',{staticClass:"dropzone-profile-course seo-instructor"},[_c('md-field',{staticClass:"pt-0 d-flex",staticStyle:{"margin":"0","justify-content":"flex-start"}},[_c('md-file',{staticClass:"design-dropzone instructor-modal col-12 px-0 border-0",style:({
                        backgroundImage: `url('${
                          _vm.formData.categorySeoFile.url
                            ? _vm.formData.categorySeoFile.url
                            : require('@/assets/images/logo/placeholder.png')
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        zIndex: '11',
                      }),attrs:{"accept":"image/*"},on:{"md-change":function($event){return _vm.uploadSeoImage($event)}}}),_c('div',{staticClass:"textUploadImage"},[_c('p',[_vm._v("Sube la imagen principal")])])],1)],1)])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2"},[_c('div',{staticClass:"text-left mt-3"},[_c('h4',{staticClass:"text-bold mb-0"},[_vm._v("TITULO SEO")])]),_c('base-input',{attrs:{"name":"name","placeholder":"Coloca el Titulo SEO"},model:{value:(_vm.formData.seoTitle),callback:function ($$v) {_vm.$set(_vm.formData, "seoTitle", $$v)},expression:"formData.seoTitle"}})],1),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 d-flex flex-column p-2"},[_c('div',{staticClass:"text-left mt-3"},[_c('h4',{staticClass:"text-bold mb-0"},[_vm._v("DESCRIPCIÓN SEO")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.seoDescription),expression:"formData.seoDescription"}],staticClass:"form-control mt-3",attrs:{"placeholder":"Descripción SEO","rows":"10"},domProps:{"value":(_vm.formData.seoDescription)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "seoDescription", $event.target.value)}}})])])]),_c('md-dialog-actions',{staticClass:"modal-footer",staticStyle:{"flex-direction":"inherit !important"}},[_c('md-button',{staticClass:"md-primary w-auto",on:{"click":_vm.closeModal}},[_vm._v("CANCELAR ")]),_c('base-button',{staticClass:"md-primary w-auto",attrs:{"loading":_vm.isLoading,"type":"submit"},on:{"click":_vm.updateCourseCategory}},[_vm._v("ACTUALIZAR ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }